export const SITEMAP_FEATURE_ENABLED = process.env.REACT_APP_SITEMAP === 'true';
export const PARKING_FEATURE_ENABLED = process.env.REACT_APP_PARKING === 'true';
export const CAMERA_PROGRES_FEATURE_ENABLED = process.env.REACT_APP_CAMERA_PROGRESSPAGE === 'true';
export const LOGGIA_ERROR_18717 = process.env.REACT_APP_LOGGIA_ERROR_18717 === 'true';
export const LOADER_19286_19285 = process.env.REACT_APP_NEW_LOADER_19286_19285 === 'true';
export const LOADER_19270 = process.env.REACT_APP_NEW_LOADER_19270 === 'true';
export const LOADER_19271 = process.env.REACT_APP_NEW_LOADER_19271 === 'true';
export const LOADER_19274 = process.env.REACT_APP_NEW_LOADER_19274 === 'true';
export const LOADER_19276 = process.env.REACT_APP_NEW_LOADER_19276 === 'true';
export const LOADER_19278 = process.env.REACT_APP_NEW_LOADER_19278 === 'true';
export const LOADER_19281 = process.env.REACT_APP_NEW_LOADER_19281 === 'true';
export const VIEW_ON_WATER_20461 = process.env.REACT_APP_VIEW_ON_WATER_FORMA_20461 === 'true';
export const PROGRESS_SLIDES_20339 = process.env.REACT_APP_PROGRESS_SLIDES_20339 === 'true';
export const CONTACTS_ERROR_PLUG_19289 = process.env.REACT_APP_CONTACTS_ERROR_PLUG_19289 === 'true';
export const LIST_ERROR_21027 = process.env.REACT_APP_LIST_ERROR_21027 === 'true';
export const WHITE_BOX_20774 = process.env.REACT_APP_WHITE_BOX_20774 === 'true';
export const WHITE_BOX_20775 = process.env.REACT_APP_WHITE_BOX_20775 === 'true';
export const LOADER_21127 = process.env.REACT_APP_NEW_LOADER_21127 === 'true';
export const LOADER_PURCHASE_TERMS = process.env.REACT_APP_PURCHASE_TERMS_LOADER_19284 === 'true';
export const IS_NEW_PDF_LINK_ENABLED = process.env.REACT_APP_NEW_PDF_LINK_21511 === 'true';
export const CHANGE_GENPLAN = process.env.REACT_APP_CHANGE_GENPLAN_VISUAL_21537 === 'true';
export const DIMENSION_BUTTON_21211 = process.env.REACT_APP_DIMENSION_BUTTON_21211 === 'true';
export const MAIN_TITLE_21299 = process.env.REACT_APP_MAIN_TITLE_21299 === 'true';
export const HOUSING_8_GENPLAN_VISUAL_22179 = process.env.REACT_APP_HOUSING_8_GENPLAN_VISUAL_22179 === 'true';
export const INFRA_PARKING_21710 = process.env.REACT_APP_INFRA_PARKING_21710 === 'true';
export const BUILDING_8_FLOOR_SELECTOR_20898 = process.env.REACT_APP_BUILDING_8_FLOOR_SELECTOR_20898 === 'true';
export const HEADER_IPAD_PRO_22489 = process.env.REACT_APP_HEADER_IPAD_PRO_22489 === 'true';
export const NEW_PLAYER_22407 = process.env.REACT_APP_NEW_PLAYER_22407 === 'true';
export const NEW_SECTION_8_22738 = process.env.REACT_APP_FILTER_FOR_SECTION_8 === 'true';
export const NEW_GENPLAN_VIEW_8_22728 = process.env.REACT_APP_NEW_GENPLAN_VIEW_8_22728 === 'true';
export const NEW_POLICY_CONTENT_22874 = process.env.REACT_APP_NEW_POLICY_CONTENT_22874 === 'true';
export const BUTTON_ADDITIONAL_BUY_23140 = process.env.REACT_APP_BUTTON_ADDITIONAL_BUY_23140 === 'true';
export const BUTTON_BOOKING_DISABLE_23210 = process.env.REACT_APP_BUTTON_BOOKING_DISABLE_23210 === 'true';
export const FILTER_MORTAGAGE_TYPE_23143 = process.env.REACT_APP_FILTER_MORTAGAGE_TYPE_23143 === 'true';
export const BUTTON_OTHER_BENEFITS_23062 = process.env.REACT_APP_BUTTON_OTHER_BENEFITS_23062 === 'true';
export const EQUALIZER_BUTTON_BOOKING_OFF_23302 = process.env.REACT_APP_EQUALIZER_BUTTON_BOOKING_OFF_23302 === 'true';
export const CORPUS_LAUNCH_DATE_23509 = process.env.REACT_APP_CORPUS_LAUNCH_DATE_23509 === 'true';
export const IS_FULL_UTM_LINK = process.env.REACT_APP_FULL_UTM_LINK_23558 === 'true';
export const IS_NEW_MAIN_RENDER = process.env.REACT_APP_NEW_MAIN_RENDER_23659 === 'true';
export const HOUSING_6_VISUAL = process.env.REACT_APP_HOUSING_6_VISUAL_23489 === 'true';
export const NEW_SEAFRONT_FORMA_23761 = process.env.REACT_APP_SEAFRONT_FORMA_23761 === 'true';
export const H1_PURCHASE_TERMS = process.env.REACT_APP_H1_PURCHASE_TERMS_FORMA_22945 === 'true';
export const REFERAL_TERMS_FORMA_23853 = process.env.REACT_APP_REFERAL_TERMS_FORMA_23853 === 'true';
export const MAILTO_FORMA_23395 = process.env.REACT_APP_MAILTO_FORMA_23395 === 'true';
export const ADMIN_COMMERCIAL_PAGE_FORMA_24265 = process.env.REACT_APP_ADMIN_FORMA_24265 === 'true';
export const VERTICAL_MINI_GENPLAN_FORMA_24258 = process.env.REACT_APP_VERTICAL_MINI_GENPLAN_FORMA_24258 === 'true';
export const MAIN_TITLE_ADMIN_FORMA_24417 = process.env.REACT_APP_MAIN_TITLE_ADMIN_FORMA_24417 === 'true';
export const DYNAMIC_ATTRIBUTES_FORMA_24433 = process.env.REACT_APP_DYNAMIC_ATTRIBUTES_FORMA_24433 === 'true';
export const CHANGE_LINK_FORMA_24720 = process.env.REACT_APP_CHANGE_LINK_FORMA_24720 === 'true';
export const NEW_CONTENT_SHIP = process.env.REACT_APP_NEW_CONTENT_SHIP_FORMA_24526 === 'true';
export const NEW_MAIN_PAGE = process.env.REACT_APP_NEW_MAIN_PAGE_24869 === 'true';
export const WHITEBOX_ADMIN_DATA = process.env.REACT_APP_WHITEBOX_ADMIN_DATA_24513 === 'true';
export const GUIDE_MORTGAGE_24686 = process.env.REACT_APP_GUIDE_MORTGAGE_24686 === 'true';
